import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Input, Row, Form, FormGroup, Label, ModalFooter } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import Empty from '../../../Components/Empty/Index'
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../Components/Pagination/Pagination';
import SweetAlert from 'sweetalert2';
import moment from 'moment';
import CommonModal from '../../../Components/Modals/modal';
import { addRummyBet, getRummyBet, isOpenModal, isOpenStatusModal, ModalToggle, rummyBetDelete, statusToggle, statusUpdateRummyBet, updateRummyBet } from '../../../store/rummyBetSlice';

function RummyBetTable() {
  const storeVar = useSelector(state => state.rummyBet)
  const dispatch = useDispatch();
  const toggle = () => dispatch(ModalToggle());
  const statusModalToggle = () => dispatch(statusToggle());
  const [typingTimer, setTypingTimer] = useState(null);
  const typingDelay = 800;
  const [stateStatus, setStateStatus] = useState('ACTIVE');
  const [submit, setSubmit] = useState(false);
  const [formVar, setFormVar] = useState({
    keyword: '',
    limit: 10,
    offset: 0,
    status: 'ACTIVE',
    currentPage: 1,
    modalTitle: null,
    editState: false,
    rummyBetId: null,

    gameType: 'pointrummy',
    gameName: 'Rummy',
    entryFee: '',
    commission: '',
    maxSeat: '',
    expireIn: 0,
  });


  useEffect(() => {
    dispatch(getRummyBet(formVar.limit, formVar.offset, formVar.status, formVar.keyword))

  }, []);
  const itemsLimitChange = (limit) => {
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      limit: limit
    }))
    dispatch(getRummyBet(limit, formVar.offset, formVar.status, formVar.keyword))

  }
  const pageChange = (page) => {
    const offset = formVar.limit * (page - 1)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))
    dispatch(getRummyBet(formVar.limit, offset, formVar.status, formVar.keyword))

  };

  const searchWithDelay = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, keyword: e.target.value }))
    clearTimeout(typingTimer);
    const timer = setTimeout(() => {
      dispatch(getRummyBet(formVar.limit, formVar.offset, formVar.status, e.target.value))

    }, typingDelay);
    setTypingTimer(timer);
  };
  const addToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: false,
      gameType: 'pointrummy',
      gameName: 'Rummy',
      entryFee: '',
      commission: '',
      maxSeat: '',
      expireIn: 0,
      modalTitle: 'Add Bet',
    }))
  }
  const editToggleModal = (data) => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      rummyBetId: data._id,
      gameType: data.gameType,
      gameName: data.gameName,
      entryFee: data.entryFee,
      commission: data.commission,
      maxSeat: data.maxSeat,
      expireIn: data.expireIn,
      modalTitle: 'Edit Bet'
    }))
  }
  const statusToggleModal = (data) => {
    dispatch(isOpenStatusModal(true))
    setStateStatus(data.status)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      rummyBetId: data._id,
    }))
  }
  const submitBet = () => {
    if (gameNameValid() ||
      gameTypeValid() ||
      entryFeeValid() ||
      maxSeatValid() ||
      // expireInValid() ||
      commissionValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    if (formVar.editState) {
      dispatch(updateRummyBet({
        id: formVar.rummyBetId,
        gameType: formVar.gameType,
        gameName: formVar.gameName,
        entryFee: formVar.entryFee,
        commission: formVar.commission,
        maxSeat: formVar.maxSeat,
        expireIn: formVar.expireIn,
      }))
    } else {
      dispatch(addRummyBet({
        gameType: formVar.gameType,
        gameName: formVar.gameName,
        entryFee: formVar.entryFee,
        commission: formVar.commission,
        maxSeat: formVar.maxSeat,
        expireIn: formVar.expireIn,
      }))
    }
  }
  const submitStatus = () => {
    dispatch(statusUpdateRummyBet({ id: formVar.rummyBetId, status: stateStatus }))
  }
  const betDelete = (id) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(rummyBetDelete({ id }))
        }
      });
  }
  const handleInputChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, status: e.target.value }))
    dispatch(getRummyBet(formVar.limit, formVar.offset, e.target.value, formVar.keyword))
  };
  const getDate = (date) => {
    if (date) {
      return moment(date).format('MMM Do YYYY')
    }
  }

  const gameNameValid = () => {
    if (!formVar.gameName) {
      return "Name is required";
    }
  }
  const gameTypeValid = () => {
    if (!formVar.gameType) {
      return "Game Type is required";
    }
  }
  const entryFeeValid = () => {
    if (!formVar.entryFee) {
      return "Entry Fee is required";
    }
  }
  const maxSeatValid = () => {
    if (!formVar.maxSeat) {
      return "Max Seat is required";
    }
  }
  // const expireInValid = () => {
  //   if (!formVar.expireIn) {
  //     return "Expire is required";
  //   }
  // }
  const commissionValid = () => {
    if (!formVar.commission) {
      return "Commission is required";
    }
  }
  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row className='sm:gap-1'>
              <Col md="6">
                <Input className="form-control" placeholder='Serch..' type="text" id="yourInputId"
                  value={formVar.keyword} onChange={(e) => searchWithDelay(e)}
                />
              </Col>
              <Col md="4">
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.status} onChange={handleInputChange}>
                  <option value='ACTIVE'>ACTIVE</option>
                  <option value='DEACTIVE'>DEACTIVE</option>
                </Input>
              </Col>
              <Col md="2" className='d-flex justify-content-end align-items-center gap-2'>
                <div className="text-end border-2 w-100">
                  <Btn attrBtn={{ color: 'info-gradien', className: 'w-100', size: 'sm', onClick: addToggleModal }}>
                    Add Bet
                  </Btn>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  {/* <th scope='col'>Game Play Type</th> */}
                  <th scope='col'>Name</th>
                  <th scope='col'>Type</th>
                  <th scope='col'>Boot Amount</th>
                  <th scope='col'>Max Seat</th>
                  {/* <th scope='col'>Expire</th> */}
                  <th scope='col'>Commission</th>
                  <th scope='col'>Created At</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar.rummyBetData?.map((item, index) => (
                  <tr key={index}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.gameName}</td>
                    <td>{item.gameType}</td>
                    <td>₹{item.entryFee}</td>
                    <td>{item.maxSeat}</td>
                    {/* <td>{item.expireIn}</td> */}
                    <td>{item.commission}</td>
                    <td>{getDate(item.createdAt)}</td>
                    <td>                      {
                      item.status === 'ACTIVE' && <>
                        <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                          {item.status === 'ACTIVE' && (
                            <i className="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
                          )}
                          &nbsp; <span>ACTIVE</span>
                        </span>
                      </>
                    }
                      {
                        item.status === 'DEACTIVE' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DEACTIVE' && (
                              <i className="fa fa-times-circle-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>DEACTIVE</span>
                          </span>
                        </>
                      }</td>
                    <td>
                      <div className='d-flex gap-2'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <i className="fa fa-edit fa-lg" onClick={(e) => editToggleModal(item)}></i>
                          <div className="tooltipCustom">Edit</div>
                        </div>
                        <div className='cursor-pointer bg-light-info font-info action-icon'>
                          <i className="fa fa-toggle-on fa-lg" onClick={(e) => statusToggleModal(item)}></i>
                          <div className="tooltipCustom">Status Update</div>
                        </div>
                        <div className='cursor-pointer bg-light-danger font-danger action-icon'>
                          <i className="fa fa-trash fa-lg" aria-hidden="true" onClick={(e) => betDelete(item._id)}></i>
                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {
            storeVar.rummyBetData?.length <= 0 && (
              <Empty />
            )
          }
        </Card>
        {
          storeVar.rummyBetData?.length > 0 &&
          <Pagination currentPage={formVar.currentPage} totalItem={storeVar.totalRrummyBet} limitSelect={true}
            itemsPerPage={formVar.limit} showEllipsisAfter={true} visiblePageCount={3} onPageChange={pageChange} onItemsLimitChange={itemsLimitChange} />
        }
      </Col>

      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} >
        <div className="login-main login-tab">
          <Form>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label" for="recipient-name">Game Name</Label>
                  <Input className="form-control" type="text" placeholder='Enter Name' disabled
                    onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, gameName: e.target.value }))} value={formVar.gameName} />
                  {submit && gameNameValid() ? <span className='d-block font-danger'>{gameNameValid()}</span> : ""}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label" for="recipient-name">Game Type</Label>
                  <Input className="form-control" type="text" placeholder='Enter Type' disabled
                    onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, gameType: e.target.value }))} value={formVar.gameType} />
                  {submit && gameTypeValid() ? <span className='d-block font-danger'>{gameTypeValid()}</span> : ""}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup className="position-relative">
                  <Label className="col-form-label">Boot Amount</Label>
                  <Input className="form-control" type="text" placeholder='Enter Boot Fee' onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(" ", "").slice(0, 10)}
                    onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, entryFee: e.target.value }))} value={formVar.entryFee} />
                  {submit && entryFeeValid() ? <span className='d-block font-danger'>{entryFeeValid()}</span> : ""}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label" for="recipient-name">Max Seat</Label>
                  <Input className="form-control" name="select" type="select"
                    onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, maxSeat: e.target.value }))} value={formVar.maxSeat}>
                    <option value=''>Select</option>
                    <option value={2}>2</option>
                    <option value={6}>6</option>
                  </Input>
                  {/* <Input className="form-control" type="text" placeholder='Enter Seat' onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(" ", "").slice(0, 10)}
                    onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, maxSeat: e.target.value }))} value={formVar.maxSeat} /> */}
                  {submit && maxSeatValid() ? <span className='d-block font-danger'>{maxSeatValid()}</span> : ""}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {/* <Col md="6">
                <FormGroup className="position-relative">
                  <Label className="col-form-label">Expire In</Label>
                  <Input className="form-control" type="text" placeholder='Enter Expire' onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(" ", "").slice(0, 10)}
                    onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, expireIn: e.target.value }))} value={formVar.expireIn} />
                  {submit && expireInValid() ? <span className='d-block font-danger'>{expireInValid()}</span> : ""}
                </FormGroup>
              </Col> */}
              <Col md="12">
                <FormGroup>
                  <Label className="col-form-label" for="recipient-name">Commission</Label>
                  <Input className="form-control" type="text" placeholder='Enter Commission' onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(" ", "").slice(0, 10)}
                    onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, commission: e.target.value }))} value={formVar.commission} />
                  {submit && commissionValid() ? <span className='d-block font-danger'>{commissionValid()}</span> : ""}
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitBet }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>

      <CommonModal isOpen={storeVar.isStatusOpenModal} title={'Status'} toggler={statusModalToggle} >
        <Col>
          <div className='d-flex m-15 m-checkbox-inline justify-content-center custom-radio-ml'>
            <div className='radio radio-primary'>
              <Input id='radioinline1' type='radio' className="radio_animated" name='radio1' checked={stateStatus === 'ACTIVE'} onChange={(e) => setStateStatus(e.target.value)} value='ACTIVE' />
              <Label className='mb-0' for='radioinline1'>
                <span className='digits'>ACTIVE</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline2' type='radio' className="radio_animated" name='radio2' checked={stateStatus === 'DEACTIVE'} onChange={(e) => setStateStatus(e.target.value)} value='DEACTIVE' />
              <Label className='mb-0' for='radioinline2'>
                <span className='digits'>DEACTIVE</span>
              </Label>
            </div>
          </div>
        </Col>
        <ModalFooter className='justify-content-center'>
          <Btn attrBtn={{ color: 'secondary', onClick: statusModalToggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitStatus }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
    </Fragment>
  )
}

export default RummyBetTable