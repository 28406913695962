import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import TeenPattiBetTable from './TeenPattiBetTable';
const TeenPattiBet = () => {
  return (
    <Fragment>
      <Breadcrumbs parent='Bet' subParent='Teen Patti Bet List' mainTitle='Teen Patti Bet List' />
      <Container fluid={true}>
        <Row>
          <TeenPattiBetTable />
        </Row>
      </Container>
    </Fragment>
  );
};

export default TeenPattiBet;
