import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';


const initialState = {
  ludoBetData: [],
  totalLudoBetData: 0,
  isMoneyOpenModal: false,
  isOpenModal: false,
  isStatusOpenModal: false,
}

export const ludoBetSlice = createSlice({
  name: "ludoBet",
  initialState,
  reducers: {
    setLudoBetData(state, { payload }) {
      state.ludoBetData = payload.result
      state.totalLudoBetData = payload.total
    },
    updateLudoBetData(state, { payload }) {
      const objIndex = state.ludoBetData.findIndex((obj) => obj._id === payload._id);
      if (objIndex >= 0) {
        state.ludoBetData[objIndex] = payload
      }
    },
    ludoBetDataDelete(state,{payload}){
      const objIndex = state.ludoBetData.findIndex((obj) => obj._id === payload._id);
      if(objIndex>=0){
        state.ludoBetData.splice(objIndex,1)
        state.totalLudoBetData = state.totalLudoBetData-1
      }
    },
    pushLudoBetData(state, { payload }) {
      state.ludoBetData.push(payload)
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
  }
})

export const { setLudoBetData, isOpenModal, ModalToggle, isOpenStatusModal, pushLudoBetData,
  statusToggle, updateLudoBetData,ludoBetDataDelete } = ludoBetSlice.actions;

export default ludoBetSlice.reducer;

/*LOGIN GET OTP*/
export function getLudoBet(limit, offset, status, keyword) {
  return async function getLudoBetThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getLudoBet(limit, offset, status, keyword).then(
        (response) => {
          if (response.data) {
            dispatch(setLudoBetData(response.data));
            dispatch(setLoading(false))
          }
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addLudoBet(body) {
  return async function addLudoBetThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addLudoBet(body).then(
        (response) => {
          if (response.data) {
            dispatch(setLoading(false))
            dispatch(isOpenModal(false))
            dispatch(pushLudoBetData(response.data))
            successHandler('Added Successfully')
          }
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateLudoBet(body) {
  return async function updateLudoBetThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.updateLudoBet(body).then(
        (response) => { 
          if (response.data) {
            dispatch(setLoading(false))
            dispatch(updateLudoBetData(response.data))
            dispatch(isOpenModal(false))
            successHandler('Update Successfully')
          }
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function statusUpdateLudoBet(payload) {
  return async function statusUpdateLudoBetThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateLudoBet(payload.id, payload.status).then(
        (response) => {
          dispatch(updateLudoBetData(response.data))
          dispatch(setLoading(false))
          dispatch(statusToggle())
          successHandler('updateed Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function ludoBetDelete(payload) {
  return async function ludoBetDeleteThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.ludoBetDelete(payload.id).then(
        (response) => {
          dispatch(ludoBetDataDelete(response.data.data))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}