import React, { Fragment, useState, useContext } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P } from "../AbstractElements";
import { LoginId, Password } from "../Constant";

import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo/logo.png"
import CustomizerContext from "../_helper/Customizer";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginUser } from "../store/authSlice";
import { setLoading } from "../store/loader";

const AdminSignin = ({ selected }) => {
  const dispatch = useDispatch();
  const [loginId, setloginId] = useState("");
  const [password, setPassword] = useState("");
  const [logintype] = useState('Admin');
  const [submit, setSubmit] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);

  const loginAuth = async (e) => {
    e.preventDefault();
    if (loginidValid() || passwordValid() || loginTypeValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    dispatch(loginUser({ loginId: loginId, password: password, logintype: logintype, history, layoutURL }));
  };
  const loginidValid = () => {
    if (!loginId) {
      return "loginId is required";
    }
  }
  const loginTypeValid = () => {
    if (!logintype) {
      return "Login Type is required";
    }
  }
  const passwordValid = () => {
    if (!password) {
      return "password is required";
    } else if (password.length < 5) {
      return "password must be longer than or equal to 5 characters";
    }
  }
  const navigater=(link)=>{
    if(link){
      dispatch(setLoading(true))
      history(link)
      setTimeout(() => {
        dispatch(setLoading(false))
      }, 800);
    }
  }
  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col lg="6" sm="12" xs="12">
            <div className="login-card">
              <div className="login-main login-tab">
                {/* <div className="login-page-logo-image">
                  <img src={logo} alt="" />
                </div> */}
                <Form className="theme-form">
                  <div className="sign-header">
                    <H4 attrH4={{className:"text-uppercase"}}>{selected === "simpleLogin" ? "" : "Sign In With Admin"}</H4>
                    <P>{"Enter your loginId & password to login"}</P>
                  </div>
                  <FormGroup>
                    <Label className="col-form-label fw-bold">{LoginId}</Label>
                    <Input className="form-control" type="text" placeholder="Enter login id" onChange={(e) => setloginId(e.target.value)} value={loginId} />
                    {submit && loginidValid() ? <span className='d-block font-danger'>{loginidValid()}</span> : ""}
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label fw-bold">{Password}</Label>
                    <div className="position-relative">
                      <Input className="form-control" type={togglePassword ? "text" : "password"} placeholder="Enter password" onChange={(e) => setPassword(e.target.value)} value={password} />
                      <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </div>
                    {submit && passwordValid() ? <span className='d-block font-danger'>{passwordValid()}</span> : ""}
                  </FormGroup>
                  <div className="position-relative form-group mt-4 mb-0">
                    {/* <Label className="d-flex justify-content-start align-items-center cursor-pointer w-11-r" >
                      <div className="m-r-10 cursor-pointer">
                        <input id="inline-form-1" className='cursor-pointer' type="checkbox" />
                      </div>
                      {RememberPassword}
                    </Label> */}
                    {/* <a className="link" href="#javascript">
                      {ForgotPassword}
                    </a> */}
                    <div className="d-flex justify-content-center align-items-center">
                      <Btn attrBtn={{ className: "buttonAgentLogin d-flex justify-content-center align-items-center w-100 mt-2 mb-2 log-butt", onClick: (e) => loginAuth(e) }}>SIGN IN</Btn>
                    </div>
                  </div>
                  <Row>
                    <Col lg='6'>
                      <button className="glow-on-hover" type="button" onClick={()=>navigater('/login')}>
                        <i className="fa fa-home"></i> LOGIN SUPER ADMIN
                      </button>
                    </Col>
                    <Col lg='6'>
                      <button className="glow-on-hover" type="button" onClick={()=>navigater('/agent_login')}>
                          <i className="fa fa-home"></i> LOGIN WITH AGENT
                        </button>
                    </Col>
                  </Row>
                  {/* <OtherWay /> */}
                </Form>
              </div>
            </div>
          </Col>
          <Col lg="6" sm="12">
            <div className="login-page-left-image">
              <img src={logo} alt="" />
            </div>
          </Col>
        </Row>
      </Container >
      <ToastContainer />
    </Fragment >
  );
};

export default AdminSignin;
