import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Input, Row, ModalFooter, Container, Button } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import Empty from '../../../Components/Empty/Index'
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../Components/Pagination/Pagination';
import moment from 'moment';
import { getJantaHistory, isOpenModal, ModalToggle } from '../../../store/jantaSlice';
import CommonModal from '../../../Components/Modals/modal';

function JantaVoldyTable() {
  const storeVar = useSelector(state => state.janta)
  const dispatch = useDispatch();
  const toggle = () => dispatch(ModalToggle());
  const [typingTimer, setTypingTimer] = useState(null);
  const typingDelay = 800;
  const [formVar, setFormVar] = useState({
    keyword: '',
    limit: 10,
    offset: 0,
    currentPage: 1,
    fromDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    toDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    modalTitle: null,
    editState: false,
    adminId: null,
    winningNumber: null,
    winningType: '',
    highlightedItems: [],
  });


  useEffect(() => {
    dispatch(getJantaHistory(formVar.limit, formVar.offset, formVar.keyword, formVar.fromDate, formVar.toDate))

  }, []);
  const itemsLimitChange = (limit) => {
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      limit: limit
    }))
    dispatch(getJantaHistory(limit, formVar.offset, formVar.keyword, formVar.fromDate, formVar.toDate))

  }
  const pageChange = (page) => {
    const offset = formVar.limit * (page - 1)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))
    dispatch(getJantaHistory(formVar.limit, offset, formVar.keyword, formVar.fromDate, formVar.toDate))

  };

  const searchWithDelay = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, keyword: e.target.value }))
    clearTimeout(typingTimer);
    const timer = setTimeout(() => {
      dispatch(getJantaHistory(formVar.limit, formVar.offset, e.target.value, formVar.fromDate, formVar.toDate))

    }, typingDelay);
    setTypingTimer(timer);
  };
  const dateFilterChangeFronDate = () => {
    dispatch(getJantaHistory(formVar.limit, formVar.offset, formVar.keyword, formVar.fromDate, formVar.toDate))

  }
  const showToggleModal = (data) => {
    console.log(data);

    dispatch(isOpenModal(true))
    // const highlightedItems = data.totalBetObject.map(bet => bet.item === '-1' ? bet.type : bet.item);
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      highlightedItems: data.totalBetObject,
      winningNumber: data.winningNumber,
      winningType: data.type,
    }))
  }
  const getBetDetails = (item) => {
    // const betDetailsHighlighted = formVar.highlightedItems.find(bet => bet.item === item || bet.type === item);
    const betDetails = formVar.highlightedItems
      .filter(bet => bet.item === item || bet.type === item)
      .reduce((acc, bet) => {
        const existingBet = acc.find(b => b.item === bet.item);
        if (existingBet) {
          existingBet.bet += bet.bet;
        } else {
          acc.push({ item: bet.item, bet: bet.bet });
        }
        return acc;
      }, []);
    return betDetails.length > 0 ? { isHighlighted: true, bet: betDetails[0]?.bet } : { isHighlighted: false, bet: 0 };
  };
  const totalBetCount = (item) => {
    let totalBet =0
    if(item.length>0){
      totalBet = item.reduce((sum, currentBet) => sum + currentBet.bet, 0);
    }
    return totalBet
  }
  const getWiner = (item) => {
    return formVar.winningNumber === item || formVar.winningType === item
  }
  // const isHighlighted = (item) => formVar.highlightedItems.includes(item);

  const getDateTime = (date) => {
    if (date) {
      return moment(date).format('MMM Do YYYY hh:mm A')
    }
  }
  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row className='sm:gap-1'>
              <Col md="4" sm='gap-1 py-1'>
                <Input className="form-control" placeholder='Serch..' type="text" id="yourInputId"
                  value={formVar.keyword} onChange={(e) => searchWithDelay(e)}
                />
              </Col>
              <Col md="3" sm='gap-1 py-1'>
                <Input className="form-control form-control-inverse btn-square" name="select" type="date" value={formVar.fromDate}
                  onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, fromDate: e.target.value }))} />
              </Col>
              <Col md="3" sm='gap-1 py-1'>
                <Input className="form-control form-control-inverse btn-square" name="select" type="date" value={formVar.toDate}
                  min={formVar.fromDate} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, toDate: e.target.value }))} />
              </Col>
              <Col md="2" className='d-flex justify-content-end align-items-center gap-2'>
                <div className="text-end border-2 w-100">
                  <Btn attrBtn={{ color: 'info-gradien', className: 'w-100', size: 'sm', onClick: dateFilterChangeFronDate }}>
                    Submit
                  </Btn>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  {/* <th scope='col'>Game Play Type</th> */}
                  <th scope='col'>Date and Time</th>
                  <th scope='col'>Table Id</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Bet</th>
                  <th scope='col'>Winning</th>
                  <th scope='col'>Won Number</th>
                  {/* <th scope='col'>Room Id</th> */}
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar.jantaHistoryData?.map((item, index) => (
                  <tr key={index}>
                    <th scope='row'>{index + 1}</th>
                    {/* <td>{item.gamePlayType}</td> */}
                    <td>{getDateTime(item.createdAt)}</td>
                    <td><span className='text-break'>{item.tableId} </span></td>
                    {/* <td>{item.gameId}</td> */}
                    <td>{item.userDetails?.name}</td>
                    <td>₹{totalBetCount(item.totalBetObject)}</td>
                    <td>₹{item.winningAmount}</td>
                    <td>{item.winningNumber}</td>
                    <td>
                      <div className='d-flex gap-2'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <i className="fa fa-eye fa-lg" onClick={(e) => showToggleModal(item)}></i>
                          <div className="tooltipCustom">View</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {
            storeVar.jantaHistoryData?.length <= 0 && (
              <Empty />
            )
          }
        </Card>
        {
          storeVar.jantaHistoryData.length > 0 &&
          <Pagination currentPage={formVar.currentPage} totalItem={storeVar.totalJantaHistory} limitSelect={true}
            itemsPerPage={formVar.limit} showEllipsisAfter={true} visiblePageCount={3} onPageChange={pageChange} onItemsLimitChange={itemsLimitChange} />
        }
      </Col>

      <CommonModal isOpen={storeVar.isOpenModal} title={'Janta Table'} toggler={toggle} size="lg">
        <div className="login-main login-tab">
          <Container className="app-container">
            {/* Grid for number buttons */}
            <Row className="justify-content-center number-grid">
              <Col xs={2}>
                <Button className={`number-button ${getBetDetails('onetofive').isHighlighted ? 'highlight' : ''}`}>
                  {getWiner('onetofive') && <div className={getWiner('onetofive') ? 'winner' : ''}></div>}
                  <div className='number-container'><div className='number'>1-5</div><div className='x9'>9x</div></div>
                  {getBetDetails('onetofive').bet > 0 && <span>₹ {getBetDetails('onetofive').bet}</span>}
                </Button>
              </Col>
              <Col xs={2}>
                <Button className={`number-button ${getBetDetails('1').isHighlighted ? 'highlight' : ''} `}>
                  {getWiner('1') && <div className={getWiner('1') ? 'winner' : ''}></div>}
                  <div className='number-container'><div className='number'>1</div><div className='x9'>9x</div></div>
                  {getBetDetails('1').bet > 0 && <span>₹ {getBetDetails('1').bet}</span>}
                </Button>
              </Col>
              <Col xs={2}>
                <Button className={`number-button ${getBetDetails('2').isHighlighted ? 'highlight' : ''}`}>
                  {getWiner('2') && <div className={getWiner('2') ? 'winner' : ''}></div>}
                  <div className='number-container'><div className='number'>2</div><div className='x9'>9x</div></div>
                  {getBetDetails('2').bet > 0 && <span>₹ {getBetDetails('2').bet}</span>}
                </Button>
              </Col>
              <Col xs={2}>
                <Button className={`number-button ${getBetDetails('3').isHighlighted ? 'highlight' : ''}`}>
                  {getWiner('3') && <div className={getWiner('3') ? 'winner' : ''}></div>}
                  <div className='number-container'><div className='number'>3</div><div className='x9'>9x</div></div>
                  {getBetDetails('3').bet > 0 && <span>₹ {getBetDetails('3').bet}</span>}
                </Button>
              </Col>
              <Col xs={2}>
                <Button className={`number-button ${getBetDetails('4').isHighlighted ? 'highlight' : ''}`}>
                  {getWiner('4') && <div className={getWiner('4') ? 'winner' : ''}></div>}
                  <div className='number-container'><div className='number'>4</div><div className='x9'>9x</div></div>
                  {getBetDetails('4').bet > 0 && <span>₹ {getBetDetails('4').bet}</span>}
                </Button>
              </Col>
              <Col xs={2}>
                <Button className={`number-button ${getBetDetails('5').isHighlighted ? 'highlight' : ''}`}>
                  {getWiner('5') && <div className={getWiner('5') ? 'winner' : ''}></div>}
                  <div className='number-container'><div className='number'>5</div><div className='x9'>9x</div></div>
                  {getBetDetails('5').bet > 0 && <span>₹ {getBetDetails('5').bet}</span>}
                </Button>
              </Col>
            </Row>
            <Row className="justify-content-center number-grid">
              <Col xs={2}>
                <Button className={`number-button ${getBetDetails('sixtozeroc').isHighlighted ? 'highlight' : ''}`}>
                  {getWiner('sixtozeroc') && <div className={getWiner('sixtozeroc') ? 'winner' : ''}></div>}
                  <div className='number-container'><div className='number'>6-0</div><div className='x9'>9x</div></div>
                  {getBetDetails('sixtozeroc').bet > 0 && <span>₹ {getBetDetails('sixtozeroc').bet}</span>}
                </Button>
              </Col>
              <Col xs={2}>
                <Button className={`number-button ${getBetDetails('6').isHighlighted ? 'highlight' : ''}`}>
                  {getWiner('6') && <div className={getWiner('6') ? 'winner' : ''}></div>}
                  <div className='number-container'><div className='number'>6</div><div className='x9'>9x</div></div>
                  {getBetDetails('6').bet > 0 && <span>₹ {getBetDetails('6').bet}</span>}
                </Button>
              </Col>
              <Col xs={2}>
                <Button className={`number-button ${getBetDetails('7').isHighlighted ? 'highlight' : ''}`}>
                  {getWiner('7') && <div className={getWiner('7') ? 'winner' : ''}></div>}
                  <div className='number-container'><div className='number'>7</div><div className='x9'>9x</div></div>
                  {getBetDetails('7').bet > 0 && <span>₹ {getBetDetails('7').bet}</span>}
                </Button>
              </Col>
              <Col xs={2}>
                <Button className={`number-button ${getBetDetails('8').isHighlighted ? 'highlight' : ''}`}>
                  {getWiner('8') && <div className={getWiner('8') ? 'winner' : ''}></div>}
                  <div className='number-container'><div className='number'>8</div><div className='x9'>9x</div></div>
                  {getBetDetails('8').bet > 0 && <span>₹ {getBetDetails('8').bet}</span>}
                </Button>
              </Col>
              <Col xs={2}>
                <Button className={`number-button ${getBetDetails('9').isHighlighted ? 'highlight' : ''}`}>
                  {getWiner('9') && <div className={getWiner('9') ? 'winner' : ''}></div>}
                  <div className='number-container'><div className='number'>9</div><div className='x9'>9x</div></div>
                  {getBetDetails('9').bet > 0 && <span>₹ {getBetDetails('9').bet}</span>}
                </Button>
              </Col>
              <Col xs={2}>
                <Button className={`number-button ${getBetDetails('0').isHighlighted ? 'highlight' : ''}`}>
                  {getWiner('0') && <div className={getWiner('0') ? 'winner' : ''}></div>}
                  <div className='number-container'><div className='number'>0</div><div className='x9'>9x</div></div>
                  {getBetDetails('0').bet > 0 && <span>₹ {getBetDetails('0').bet}</span>}
                </Button>
              </Col>
            </Row>
            {/* Even and Odd buttons */}
            <Row className="justify-content-center even-odd-row">
              <Col xs={2}>
                <Button className={`even-odd-button ${getBetDetails('Even').isHighlighted ? 'highlight' : ''}`}>
                  {getWiner('Even') && <div className={getWiner('Even') ? 'winner' : ''}></div>}
                  <div className='number-container'><div className='number'>Even</div></div>
                  {getBetDetails('Even').bet > 0 && <span>₹ {getBetDetails('Even').bet}</span>}
                </Button>
              </Col>
              <Col xs={2}>
                <Button className={`even-odd-button ${getBetDetails('Odd').isHighlighted ? 'highlight' : ''}`}>
                  {getWiner('Odd') && <div className={getWiner('Odd') ? 'winner' : ''}></div>}
                  <div className='number-container'><div className='number'>Odd</div></div>
                  {getBetDetails('Odd').bet > 0 && <span>₹ {getBetDetails('Odd').bet}</span>}
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          {/* <Btn attrBtn={{ color: 'primary', onClick: submitDegree }}>Save Changes</Btn> */}
        </ModalFooter>
      </CommonModal>
    </Fragment>
  )
}

export default JantaVoldyTable