import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';


const initialState = {
  teenPattiBetData: [],
  totalTeenPattiBet: 0,
  isOpenModal: false,
  isStatusOpenModal: false,
}

export const teenPattiBetSlice = createSlice({
  name: "teenPattiBet",
  initialState,
  reducers: {
    setTeenPattiBetData(state, { payload }) {
      state.teenPattiBetData = payload.result
      state.totalTeenPattiBet = payload.total
    },
    updateTeenPattiBetData(state, { payload }) {
      const objIndex = state.teenPattiBetData.findIndex((obj) => obj._id === payload._id);
      if (objIndex >= 0) {
        state.teenPattiBetData[objIndex] = payload
      }
    },
    pushTeenPattiBetData(state, { payload }) {
      state.teenPattiBetData.push(payload)
    },
    teenPattiDataDelete(state,{payload}){
      const objIndex = state.teenPattiBetData.findIndex((obj) => obj._id === payload._id);
      if(objIndex>=0){
        state.teenPattiBetData.splice(objIndex,1)
        state.totalTeenPattiBet = state.totalTeenPattiBet-1
      }
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
  }
})

export const { setTeenPattiBetData, isOpenModal, ModalToggle, isOpenStatusModal, pushTeenPattiBetData,
  statusToggle, updateTeenPattiBetData,teenPattiDataDelete } = teenPattiBetSlice.actions;

export default teenPattiBetSlice.reducer;

/*LOGIN GET OTP*/
export function getTeenPattiBet(limit, offset, status, keyword) {
  return async function getTeenPattiBetThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getTeenPattiBet(limit, offset, status, keyword).then(
        (response) => {
          if (response.data) {
            dispatch(setTeenPattiBetData(response.data));
            dispatch(setLoading(false))
          }
        }, (error) => { 
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addTeenPattiBet(body) {
  return async function addTeenPattiBetThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addTeenPattiBet(body).then(
        (response) => {
          if (response.data) {
            dispatch(setLoading(false))
            dispatch(isOpenModal(false))
            dispatch(pushTeenPattiBetData(response.data.data))
            successHandler('Added Successfully')
          }
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateTeenPattiBet(body) {
  return async function updateTeenPattiBetThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.updateTeenPattiBet(body).then(
        (response) => {
          if (response.data) {
            dispatch(setLoading(false))
            dispatch(updateTeenPattiBetData(response.data))
            dispatch(isOpenModal(false))
            successHandler('Update Successfully')
          }
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function statusUpdateTeenPattiBet(payload) {
  return async function statusUpdateTeenPattiBetThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateTeenPattiBet(payload.id, payload.status).then(
        (response) => {
          dispatch(updateTeenPattiBetData(response.data))
          dispatch(setLoading(false))
          dispatch(statusToggle())
          successHandler('Updateed Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function teenPattiBetDelete(payload) {
  return async function teenPattiBetDeleteThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.teenPattiBetDelete(payload.id).then(
        (response) => {
          dispatch(teenPattiDataDelete(response.data.data))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
