import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Input, Row, ModalFooter } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import Empty from '../../../Components/Empty/Index'
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../Components/Pagination/Pagination';
import moment from 'moment';
import { getRolleteHistory, isOpenModal, ModalToggle } from '../../../store/rolletteSlice';
import CommonModal from '../../../Components/Modals/modal';

function RoulletteTable() {
  const storeVar = useSelector(state => state.rollette)
  const dispatch = useDispatch();
  const toggle = () => dispatch(ModalToggle());
  const [typingTimer, setTypingTimer] = useState(null);
  const typingDelay = 800;
  const [formVar, setFormVar] = useState({
    keyword: '',
    limit: 10,
    offset: 0,
    currentPage: 1,
    fromDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    toDate: moment().add(1,'days').format('YYYY-MM-DD'),
    modalTitle: null,
    editState: false,
    adminId: null,
    betObjectData: [],
  });

  useEffect(() => {
    dispatch(getRolleteHistory(formVar.limit, formVar.offset, formVar.keyword, formVar.fromDate, formVar.toDate))

  }, []);

  const pageChange = (page) => {
    const offset = formVar.limit * (page - 1)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))
    dispatch(getRolleteHistory(formVar.limit, offset, formVar.keyword, formVar.fromDate, formVar.toDate))

  };

  const searchWithDelay = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, keyword: e.target.value }))
    clearTimeout(typingTimer);
    const timer = setTimeout(() => {
      dispatch(getRolleteHistory(formVar.limit, formVar.offset, e.target.value, formVar.fromDate, formVar.toDate))

    }, typingDelay);
    setTypingTimer(timer);
  };
  const dateFilterChangeFronDate = () => {
    dispatch(getRolleteHistory(formVar.limit, formVar.offset, formVar.keyword, formVar.fromDate, formVar.toDate))

  }
  const getDateTime = (date) => {
    if (date) {
      return moment(date).format('MMM Do YYYY hh:mm A')
    }
  }
  const showToggleModal = (data) => {
    dispatch(isOpenModal(true))
    // const highlightedItems = data.totalBetObject.map(bet => bet.item === '-1' ? bet.type : bet.item);
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      betObjectData: data.betObjectData,
      winningNumber: data.ballposition,
      winningType: data.type,
    }))
  }
  // Helper function to check if the bet is placed on a number or section
  const getBetForNumber = (number) => {
    if (formVar.betObjectData?.length > 0) {
      const betData = formVar.betObjectData.find(bet => bet.number && bet.number.includes(number));
      return betData ? betData.bet : null;
    }
  };

  const getBetForSection = (type) => {
    if (formVar.betObjectData?.length > 0) {
      const betData = formVar.betObjectData.find(bet => bet.type === type);
      return betData ? betData.bet : null;
    }
  };

  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row className='sm:gap-1'>
              <Col md="4" sm='gap-1 py-1'>
                <Input className="form-control" placeholder='Serch..' type="text" id="yourInputId"
                  value={formVar.keyword} onChange={(e) => searchWithDelay(e)}
                />
              </Col>
              <Col md="3" sm='gap-1 py-1'>
                <Input className="form-control form-control-inverse btn-square" name="select" type="date" value={formVar.fromDate}
                  onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, fromDate: e.target.value }))} />
              </Col>
              <Col md="3" sm='gap-1 py-1'>
                <Input className="form-control form-control-inverse btn-square" name="select" type="date" max={formVar.toDate} value={formVar.toDate}
                  min={formVar.fromDate} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, toDate: e.target.value }))} />
              </Col>
              <Col md="2" className='d-flex justify-content-end align-items-center gap-2'>
                <div className="text-end border-2 w-100">
                  <Btn attrBtn={{ color: 'info-gradien', className: 'w-100', size: 'sm', onClick: dateFilterChangeFronDate }}>
                    Submit
                  </Btn>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Date and Time</th>
                  <th scope='col'>Table Id</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Game Bet</th>
                  <th scope='col'>Game Won</th>
                  <th scope='col'>Wining Number</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar.rolletteHistoryData?.map((item, index) => (
                  <tr key={index}>
                    <th scope='row'>{index + 1}</th>
                    <td>{getDateTime(item.createdAt)}</td>
                    <td><span className='text-break'>{item.tableid} </span></td>
                    <td>{item.userDetails?.name}</td>
                    <td>{item.play}</td>
                    <td>{item.won}</td>
                    <td>{item.ballposition}</td>
                    <td>
                      <div className='d-flex gap-2'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <i className="fa fa-eye fa-lg" onClick={(e) => showToggleModal(item)}></i>
                          <div className="tooltipCustom">Edit</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {
            storeVar.rolletteHistoryData?.length <= 0 && (
              <Empty />
            )
          }
        </Card>
        {
          storeVar.rolletteHistoryData.length > 0 &&
          <Pagination currentPage={formVar.currentPage} totalItem={storeVar.totalRolletteHistory}
            itemsPerPage={formVar.limit} showEllipsisAfter={true} visiblePageCount={3} onPageChange={pageChange} />
        }
      </Col>
      <CommonModal isOpen={storeVar.isOpenModal} title={'Roullet Table'} toggler={toggle} size="lg">
        <div className="login-main login-tab">
          <div className="roulette-board">
            <div className="grid-container">
              <div key={0} className="grid-item black">
                0
                <div className="bet-amount">{getBetForNumber(0) ? `₹${getBetForNumber(0)}` : ''}</div>
              </div>

              {Array.from({ length: 36 }, (_, i) => (
                <div key={i + 1} className={`grid-item ${i % 2 === 0 ? 'red' : 'black'} ${i+1===formVar.winningNumber?'winBgActive':''}`}>
                 <div className={i+1===formVar.winningNumber?'winActive':''}>{i + 1}</div> 
                  <div className="bet-amount">{getBetForNumber(i + 1) ? `₹${getBetForNumber(i + 1)}` : ''}</div>
                </div>
              ))}
            </div>
            <div className="outside-bet-section-first">
              <div className="outside-bet">
              <div className={formVar.winningNumber==='1st12'?'winActive':''}>1st 12</div> 
              <div className="bet-amount">
                  {getBetForSection('1st12') ? `₹${getBetForSection('1st12')}` : ''}
                </div>
              </div>
              <div className="outside-bet">
              <div className={formVar.winningNumber==='2nd12'?'winActive':''}>2nd 12</div> 
              <div className="bet-amount">
                  {getBetForSection('2nd12') ? `₹${getBetForSection('2nd12')}` : ''}
                </div>
              </div>
              <div className="outside-bet">
              <div className={formVar.winningNumber==='3rd12'?'winActive':''}>3nd 12</div> 
              <div className="bet-amount">
                  {getBetForSection('3rd12') ? `₹${getBetForSection('3rd12')}` : ''}
                </div>
              </div>
            </div>
            <div className="outside-bet-section-sec">
              <div className="outside-bet">
              <div className={formVar.winningNumber==='1to18'?'winActive':''}>1-18</div> 
              <div className="bet-amount">
                  {getBetForSection('1to18') ? `₹${getBetForSection('1to18')}` : ''}
                </div>
              </div>
              <div className="outside-bet">
              <div className={formVar.winningNumber==='even'?'winActive':''}>Even</div> 
                <div className="bet-amount">
                  {getBetForSection('even') ? `₹${getBetForSection('even')}` : ''}
                </div>
              </div>
              <div className="outside-bet">
              <div className={formVar.winningNumber==='red'?'winActive':''}>Red</div> 
              <div className="bet-amount">
                  {getBetForSection('red') ? `₹${getBetForSection('red')}` : ''}
                </div>
              </div>
              <div className="outside-bet">
              <div className={formVar.winningNumber==='black'?'winActive':''}>Black</div> 
              <div className="bet-amount">
                  {getBetForSection('black') ? `₹${getBetForSection('black')}` : ''}
                </div>
              </div>
              <div className="outside-bet">
              <div className={formVar.winningNumber==='odd'?'winActive':''}>Odd</div> 
                <div className="bet-amount">
                  {getBetForSection('odd') ? `₹${getBetForSection('odd')}` : ''}
                </div>
              </div>
              <div className="outside-bet">
              <div className={formVar.winningNumber==='19to36'?'winActive':''}>19-36</div> 
              <div className="bet-amount">
                  {getBetForSection('19to36') ? `₹${getBetForSection('19to36')}` : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          {/* <Btn attrBtn={{ color: 'primary', onClick: submitDegree }}>Save Changes</Btn> */}
        </ModalFooter>
      </CommonModal>
    </Fragment>
  )
}

export default RoulletteTable