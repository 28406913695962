import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';


const initialState = {
  rummyBetData: [],
  totalRrummyBet: 0,
  isMoneyOpenModal: false,
  isOpenModal: false,
  isStatusOpenModal: false,
}

export const rummyBetSlice = createSlice({
  name: "rummyBet",
  initialState,
  reducers: {
    setRummyBetData(state, { payload }) {
      state.rummyBetData = payload.result
      state.totalRrummyBet = payload.total
    },
    updateRummyBetData(state, { payload }) {
      const objIndex = state.rummyBetData.findIndex((obj) => obj._id === payload._id);
      if (objIndex >= 0) {
        state.rummyBetData[objIndex] = payload
      }
    },
    rummyBetDataDelete(state,{payload}){
      const objIndex = state.rummyBetData.findIndex((obj) => obj._id === payload._id);
      if(objIndex>=0){
        state.rummyBetData.splice(objIndex,1)
        state.totalRrummyBet = state.totalRrummyBet-1
      }
    },
    pushRummyBetData(state, { payload }) {
      state.rummyBetData.push(payload)
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
  }
})

export const { setRummyBetData, isOpenModal, ModalToggle, isOpenStatusModal, pushRummyBetData,
  statusToggle, updateRummyBetData,rummyBetDataDelete } = rummyBetSlice.actions;

export default rummyBetSlice.reducer;

/*LOGIN GET OTP*/
export function getRummyBet(limit, offset, status, keyword) {
  return async function getRummyBetThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getRummyBet(limit, offset, status, keyword).then(
        (response) => {
          if (response.data) {
            dispatch(setRummyBetData(response.data));
            dispatch(setLoading(false))
          }
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addRummyBet(body) {
  return async function addRummyBetThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addRummyBet(body).then(
        (response) => {
          if (response.data) {
            dispatch(setLoading(false))
            dispatch(isOpenModal(false))
            dispatch(pushRummyBetData(response.data))
            successHandler('Added Successfully')
          }
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateRummyBet(body) {
  return async function updateRummyBetThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.updateRummyBet(body).then(
        (response) => {
          if (response.data) {
            dispatch(setLoading(false))
            dispatch(updateRummyBetData(response.data))
            dispatch(isOpenModal(false))
            successHandler('Update Successfully')
          }
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function statusUpdateRummyBet(payload) {
  return async function statusUpdateRummyBetThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateRummyBet(payload.id, payload.status).then(
        (response) => {
          dispatch(updateRummyBetData(response.data))
          dispatch(setLoading(false))
          dispatch(statusToggle())
          successHandler('updateed Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function rummyBetDelete(payload) {
  return async function rummyBetDeleteThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.rummyBetDelete(payload.id).then(
        (response) => {
          dispatch(rummyBetDataDelete(response.data.data))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}